import { hasCoupons } from '@wix/ambassador-coupons-api-v1-coupon/http';
import {
  memberGetOrder,
  getOnlineOrderPreview,
  createOnlineOrder,
  changeStartDate,
  getPricePreview,
  applyCoupon,
} from '@wix/ambassador-pricing-plans-v2-order/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { PurchaseLimitExceededError } from '../utils/errors';

export class OrdersApi {
  constructor(protected httpClient: IHttpClient) {}

  async getOrder(id: string) {
    const response = await this.httpClient.request(memberGetOrder({ id }));
    const { order } = response.data;
    return order;
  }

  public async createOrder(planId: string) {
    const previewResponse = await this.httpClient.request(
      getOnlineOrderPreview({
        planId,
        startDate: new Date(),
      }),
    );
    const { purchaseLimitExceeded } = previewResponse.data;
    if (purchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    const response = await this.httpClient.request(createOnlineOrder({ planId }));
    const { order } = response.data;
    return order;
  }

  public async updateOrderValidFrom(orderId: string, validFrom: Date) {
    const response = await this.httpClient.request(
      changeStartDate({
        orderId,
        startDate: validFrom,
      }),
    );
    const { order } = response.data;
    return order;
  }

  public async getPricePreview(planId: string, couponCode?: string) {
    const response = await this.httpClient.request(getPricePreview(couponCode ? { planId, couponCode } : { planId }));
    const { prices } = response.data;
    return prices;
  }

  public async applyCoupon(orderId: string, couponCode: string) {
    const response = await this.httpClient.request(applyCoupon({ orderId, couponCode }));
    const { order } = response.data;
    return order;
  }

  public async hasCoupons() {
    try {
      const response = await this.httpClient.request(hasCoupons({}));
      return response.data.hasCoupons;
    } catch (e) {
      // @todo: do we need to log error here?
      return false;
    }
  }
}

export function createOrdersApi(httpClient: IHttpClient) {
  return new OrdersApi(httpClient);
}
